<template>
  <div>
    <van-popup v-model="show" position="center" style="border-radius: .16rem;">
      <div class="mobile-submit-order-container">
        <div class="title">是否确认交易?</div>
        <div class="detail">本次兑换将扣除您{{ Math.round(money * 100000) / 100000 }}元余额</div>
        <div class="btn-item">
          <div class="btn" @click="show = false">取消</div>
          <div class="btn sure" @click="handleSubmit">
            <van-loading v-if="submiting" color="#ffffff" />
            <span v-else>确定</span>
          </div>
        </div>
      </div>
    </van-popup>

    <van-dialog
      v-model="orderRes.show"
      confirm-button-text="确定"
      :lazy-render="false"
      @confirm="handleHideOrderRes"
    >
    <div class="order-res-item">
      <div class="total">
        <span>已提交: {{ orderRes.data | getOrderResCount(1) }};</span>
        <span class="success">成功: {{ orderRes.data | getOrderResCount(2) }};</span>
        <span class="fail">失败: {{ orderRes.data | getOrderResCount(3) }};</span>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in orderRes.data" :key="index" :class="item.error == 0 ? 'success' : 'fail'">{{ item.msg }}</div>
      </div>
    </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'MobileSubmitOrder',
  data() {
    return {
      orderRes: {
        show: false,
        data: []
      },
      submiting: false,
      show: false,
      params: {},
      money: ''
    }
  },
  filters: {
    getOrderResCount(data, type) {
      if (type == 2) {
        let list = data.filter(item => item.error == 0)
        return list.length
      }
      if (type == 3) {
        let list = data.filter(item => item.error != 0)
        return list.length
      }
      return data.length
    } 
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    open(data) {
      this.GetUserInfo()
      this.show = true
      this.params = data.params
      this.money = data.money
    },
    async handleSubmit() {
      const { params, submiting } = this
      if (submiting) {
        return
      }
      const carIds = params.carIds || []
      delete params.carIds
      this.submiting = true
      var res = await this.$API.addOrder.post(params)
      this.submiting = false
      this.show = false
      if (res.error == 0) {
        const data = JSON.parse(res.info)
        const successIds = []
        for (let index = 0; index < carIds.length; index++) {
          const item = carIds[index]
          if (data[index].error == 0) {
            successIds.push(item)
          }
        }
        this.orderRes = {
          show: true,
          data: data
        }
        this.$emit('success', successIds)
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
    handleHideOrderRes() {
      this.orderRes.show = false
      window.location.reload()
    }
  }
}
</script>

<style scoped lang="less">
.mobile-submit-order-container {
  width: 5rem;
  padding: .4rem;
  text-align: center;
  .title {
    line-height: .48rem;
    color: #11113a;
    font-size: .32rem;
    font-weight: bold;
  }
  .detail {
    margin-top: .28rem;
    color: #bcbdc5;
    line-height: .4rem;
    font-size: .28rem;
  }
  .btn-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    .btn {
      width: 2.4rem;
      height: .8rem;
      border-radius: .12rem;
      background-color: #bac0cc;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: .24rem;
      &.sure {
        background-color: #1399ff;
      }
    }
  }
}
.order-res-item {
  padding: .24rem;
  font-size: .24rem;
  color: #333;
  .total {
    padding: .1rem 0;
    span {
      margin-right: .24rem;
    }
  }
  .success {
    color: #22e1ad;
  }
  .fail {
    color: #e12b20;
  }
  .list {
    margin-top: .2rem;
    .item {
      line-height: .34rem;
    }
  }
}
</style>
